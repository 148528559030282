<div class="tw-pb-120" data-cy="notifications">
  <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
    <h1 class="msa-text-h1 tw-my-30">
      {{ "i18n.notifications.overview.notifications" | safeTranslate }}
    </h1>

    <button
      class="text-white tw-hidden tw-flex-row tw-gap-6 d:tw-flex"
      [routerLink]="PERSON_DATA_PATH"
      data-cy="notificationSettings"
      mat-button
      aria-label="open notification settings"
    >
      <mat-icon>settings</mat-icon>
      <p>{{ "i18n.notifications.overview.settings" | safeTranslate }}</p>
    </button>
  </div>

  <div class="tw-flex tw-flex-col">
    @for (group of groupedNotifications(); track group) {
      <h2 class="msa-text-h2 tw-mb-30 tw-mt-30 first:tw-mt-0">{{ group.month }}</h2>
      <div class="tw-flex tw-flex-col tw-gap-6">
        @for (notification of group.notifications; track notification.id) {
          <app-notification-card
            [unread]="notification.unread"
            [creationDateTime]="notification.creationDateTime"
            [subject]="notification.subject"
            [body]="notification.body"
            [buttonText]="notification.buttonText"
            [buttonLink]="notification.buttonLink"
            [referenceId]="notification.referenceId"
            [id]="notification.id"
            (markAsRead)="markAsRead($event)"
          />
        }
      </div>
    }
  </div>
</div>
